import React, { useEffect, useState } from "react";

// Hooks
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilters, updateGlobalStorePage, updateGlobalStorePageBis } from '../Store/action';
import useApi from '../Hooks/useApi';
import { useFetch, useFetch2 } from '../Hooks/useFetch';

// Components
import { LoaderPage, LoaderResults } from '../Components/loader';
import Metatags from '../Layout/metas-tags/meta-tags';
import MainHeader from "../Layout/main/header";
import SearchOffers from "../Components/search-offers";
import Offer from "../Components/offer";
import AlertPush from "../Components/alert-push";
import ScoringPush from "../Components/scoring-push";
import TestimonyList from "../Components/testimony-list";
import CMap from '../Components/map';
import { setCookie } from "../Utils/cookies";
import { CaaskAllOffers } from "../Components/caask";


const TemplateOfferList = ({ _uid }) => {

    const lang = useSelector(state => state.global).lang;
    const userID = useSelector(state => state.global).userId;
    let location = useLocation();
    let history = useHistory();
    const { global: { colors } } = useSelector(state => state.global);

    const cookiesFilters = useSelector(state => state.global).filters;
    const [newFilters, setNewFilters] = useState('');

    const [dataFetch, isLoaded] = useApi({ name: 'pages', _uid });

    const restricted = useSelector(state => state.global).isRestricted;
    const [restrictedBrands, setRestrictedBrands] = useState('');
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('t-offer-list'));
        dispatch(updateGlobalStorePageBis(''));
    }, [dispatch]); // isLoaded

    // Save keyword in search input
    const [value, setValue] = useState('');

    // Save selected filters
    const [selected, setSelected] = useState({
        'tax_sector': [],
        'tax_contract': [],
        'tax_place': [],
        'tax_experience': [],
        'tax_degree': [],
        'tax_brands': [],
        'tax_department': [],
        'tax_job': [],
        'tax_city': [],
        'tax_country': [],
        'tax_channel': [],
        'jobcode': [],
        'tax_community_job': []
    });

    // useEffect(() => {
    //     console.log('selected', selected);
    // }, [selected]);

    // Save params for query
    const initParams = {
        'lang': lang,
        'keyword': '',
        'tax_sector': '',
        'tax_contract': '',
        'tax_place': '',
        'tax_job': '',
        'tax_experience': '',
        'tax_degree': '',
        'tax_brands': '',
        'tax_department': '',
        'tax_city': '',
        'tax_country': '',
        'tax_channel': '',
        'jobcode': '',
        'tax_community_job': '',
        'external': false,
        'userID': userID !== null ? userID : '',
        "from": 0,
        'size': 9
    };
    const [params, setParams] = useState('');
    const [params2, setParams2] = useState('');

    // useEffect(() => {
    //     console.log('params', params);
    // }, [params]);

    const [load, setLoad] = useState(false);

    // Get Search API results
    const { response: result, dataIsLoaded: resultIsLoaded } = useFetch('search/jobs', 'post', { params });
    const { response: result2, dataIsLoaded: resultIsLoaded2 } = useFetch('search/jobs', 'post', { params: params2 });

    //map_mode=gmap
    // Set items list
    const [items, setItems] = useState([]);
    const [items2, setItems2] = useState([]);
    const [dynamicsBlocs, setDynamicsBlocs] = useState([]);
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const [itemsLoaded2, setItemsLoaded2] = useState(false);

    // Event when input search is updated
    const handleChange = (e) => {
        setItemsLoaded(false);
        setItemsLoaded2(false);
        setItems([]);
        setItems2([]);
        setValue(e);
        setParams({
            ...params,
            'from': 0,
            'keyword': e
        });
        setParams2({
            ...params,
            'from': 0,
            'keyword': e,
            'map_mode': 'gmap'
        });
    };

    // Event fired on load to set filters from url to value, params & seleted states
    const handleOnLoad = (selection, parameters, keyword, external, jobcode, tax_community_job) => {
        setItemsLoaded(false);
        setItemsLoaded2(false);
        setItems([]);
        setItems2([]);
        setParams({
            ...initParams,
            'keyword': keyword,
            'tax_sector': parameters['tax_sector'] ? parameters['tax_sector'] : '',
            'tax_contract': parameters['tax_contract'] ? parameters['tax_contract'] : '',
            'tax_place': parameters['tax_place'] ? parameters['tax_place'] : '',
            'tax_experience': parameters['tax_experience'] ? parameters['tax_experience'] : '',
            'tax_degree': parameters['tax_degree'] ? parameters['tax_degree'] : '',
            'tax_brands': parameters['tax_brands'] ? parameters['tax_brands'] : '',
            'tax_department': parameters['tax_department'] ? parameters['tax_department'] : '',
            'tax_job': parameters['tax_job'] ? parameters['tax_job'] : '',
            'tax_city': parameters['tax_city'] ? parameters['tax_city'] : '',
            'tax_country': parameters['tax_country'] ? parameters['tax_country'] : '',
            'tax_channel': parameters['tax_channel'] ? parameters['tax_channel'] : '',
            'jobcode': jobcode ? jobcode : '',
            'tax_community_job': tax_community_job ? tax_community_job : '',
            'external': external
        });
        setParams2({
            ...initParams,
            'keyword': keyword,
            'tax_sector': parameters['tax_sector'] ? parameters['tax_sector'] : '',
            'tax_contract': parameters['tax_contract'] ? parameters['tax_contract'] : '',
            'tax_place': parameters['tax_place'] ? parameters['tax_place'] : '',
            'tax_experience': parameters['tax_experience'] ? parameters['tax_experience'] : '',
            'tax_degree': parameters['tax_degree'] ? parameters['tax_degree'] : '',
            'tax_brands': parameters['tax_brands'] ? parameters['tax_brands'] : '',
            'tax_department': parameters['tax_department'] ? parameters['tax_department'] : '',
            'tax_job': parameters['tax_job'] ? parameters['tax_job'] : '',
            'tax_city': parameters['tax_city'] ? parameters['tax_city'] : '',
            'tax_country': parameters['tax_country'] ? parameters['tax_country'] : '',
            'tax_channel': parameters['tax_channel'] ? parameters['tax_channel'] : '',
            'jobcode': jobcode ? jobcode : '',
            'tax_community_job': tax_community_job ? tax_community_job : '',
            'external': external,
            'map_mode': 'gmap'
        });

        if (keyword !== '') setValue(keyword);

        setSelected(selection);
        (parameters['tax_brands']) ? setRestrictedBrands(parameters['tax_brands']) : setRestrictedBrands('');

        //(parameters['tax_brands'] && parameters['external']) ? setRestrictedBrands(parameters['tax_brands']) : setRestrictedBrands('');
        // parameters['external'] ? dispatch(updateGlobalisRestricted(true)) : dispatch(updateGlobalisRestricted(false));
    };


    // Event on select change
    const handleFilters = (e, id) => {
        // console.log('values', e);

        setItemsLoaded(false);
        setItemsLoaded2(false);
        setItems([]);
        setItems2([]);

        let values = [];
        if (id === 'tax_country') {
            values.push(e.value);
            setSelected({
                ...selected,
                [id]: [e]
            });
        } else {
            if (Array.isArray(e)) {
                e && e.map(({ value }) => values.push(value));
                setSelected({
                    ...selected,
                    [id]: e
                });
            } else {
                setSelected({
                    ...selected,
                    [id]: ''
                });
            }
        }

        setParams({ ...params, 'from': 0, [id]: values.join() });
        setParams2({ ...params, 'from': 0, [id]: values.join(), 'map_mode': 'gmap' });
    };

    // Event on remove filter
    const handleRemove = (taxo, value) => {
        // console.log('handleRemove');
        setItemsLoaded(false);
        setItemsLoaded2(false);
        setItems([]);
        setItems2([]);
        let list = params[taxo].split(',');
        let newParams = list.filter(item => item !== value);

        setParams({
            ...params,
            'from': 0,
            [taxo]: newParams.join()
        });
        setParams2({
            ...params,
            'from': 0,
            [taxo]: newParams.join(),
            'map_mode': 'gmap'
        });

        let newSelected = selected[taxo].filter(item => item.value !== value);
        setSelected({
            ...selected,
            [taxo]: newSelected
        })
    };

    // On click on Loadmore
    const handleLoadMore = () => {
        let from = parseInt(params.from) + 9;
        setParams({ ...params, 'from': from });
        setParams2({ ...params, 'from': from, 'map_mode': 'gmap' });
    };

    const [initialPlaces, setInitialPlaces] = useState([]);
    // Au chargement on récupère les params depuis l'url
    useEffect(() => {
        if (isLoaded) {

            if (dataFetch.content.top.filters && dataFetch.content.top.filters.list && dataFetch.content.top.filters.list.length > 0) {
                const places = dataFetch.content.top.filters.list.filter(item => item.id === 'tax_place');
                if (places.length > 0) {
                    setInitialPlaces(places[0].choices);
                }
            }

            // get url params and add it to keywords and filters
            let searchParams = location.search;
            // let selection = {['tax_country']: [{'label': 'France', 'value': 'france'}]}; // eslint-disable-line
            // let parameters = {['tax_country']: 'france'}; // eslint-disable-line
            let selection = {};
            let parameters = {};
            let keyword = '';
            let external = false;
            let jobcode = '';
            let tax_community_job = '';
            if (searchParams !== '') {
                let searchArray = searchParams.split('&');

                searchArray.map((term, index) => { // eslint-disable-line

                    let searchTerm = term.split('=');
                    let taxonomy = searchTerm[0];
                    let valeur = decodeURIComponent(searchTerm[1]);

                    if (index === 0) taxonomy = searchTerm[0].replace('?', '');

                    if (taxonomy === 'keyword') {
                        keyword = valeur;
                    } else if (taxonomy === 'external') {
                        if (valeur === 'true') {
                            external = true;
                        } else {
                            external = false;
                        }
                    } else if (taxonomy === 'jobcode') {
                        // jobcode = valeur.split(',');
                        jobcode = valeur;
                    } else if (taxonomy === 'jobcommunaute') {
                        tax_community_job = valeur;
                    } else if (['tax_sector', 'tax_contract', 'tax_place', 'tax_experience', 'tax_degree', 'tax_brands', 'tax_department', 'tax_job', 'tax_city', 'tax_country', 'tax_channel'].includes(taxonomy)) {

                        if (dataFetch.content.top.filters && dataFetch.content.top.filters.list && dataFetch.content.top.filters.list.length > 0) {
                            let arrayOfOptions = valeur.split(',');
                            let optionsList = dataFetch.content.top.filters.list.find(x => x.id === taxonomy);
                            let e = [];
                            let item = {};
                            if (arrayOfOptions.length > 0) {
                                arrayOfOptions.map((v) => { // eslint-disable-line
                                    let getOption = optionsList.choices.filter(item => item.value === v);
                                    if (getOption[0]) {
                                        item = {
                                            'label': [getOption[0].label].toString(),
                                            'value': v
                                        };
                                        e.push(item);
                                    }
                                });
                            }
                            selection = {
                                ...selection,
                                [taxonomy]: e
                            };
                            parameters = {
                                ...parameters,
                                [taxonomy]: valeur
                            };
                        }
                    }
                });

            }
            // selection : params formatted for setSelected
            // valeur : params formatted for setParams
            handleOnLoad(selection, parameters, keyword, external, jobcode, tax_community_job);
        }
    }, [isLoaded]); // eslint-disable-line

    // Mise à jour de la liste des offres d'emploi et des filtres
    useEffect(() => {
        if (resultIsLoaded) {
            setItems(items.concat(result.data.items));
            setDynamicsBlocs(result.data.dynamics_blocs);
        }
    }, [resultIsLoaded]); // eslint-disable-line

    useEffect(() => {
        if (resultIsLoaded2) {
            setItems2(items2.concat(result2.data.items));
        }
    }, [resultIsLoaded2]); // eslint-disable-line

    // set load state to true to active the url updater function
    // only after urls params are all set in select
    useEffect(() => {
        if (resultIsLoaded) {
            setLoad(true);
            setItemsLoaded(true);
        }
        if (resultIsLoaded2) {
            setItemsLoaded2(true);
        }
    }, [items, items2]); // eslint-disable-line


    const [paramsPlaces, setParamsPlaces] = useState({});
    const [dataFetchPlaces, isLoadedPlaces] = useApi(paramsPlaces);

    // update params in url
    useEffect(() => {
        let index = 0;
        let searchPath = '';

        setNewFilters({ ...cookiesFilters, offer: params });

        if (load) {
            for (const filter in params) { // loop sur les params
                if (params[filter] !== '' && (filter !== 'lang' && filter !== 'userID' && filter !== 'from' && filter !== 'size')) {// je ne prends que les paramètres remplis sauf lang et userID

                    let newFilter = filter;
                    if (filter === 'tax_community_job') {
                        newFilter = 'jobcommunaute';
                    }
                    if (index === 0) {
                        searchPath = `?${newFilter}=${params[filter]}`;
                    } else {
                        searchPath += `&${newFilter}=${params[filter]}`
                    }
                    index++;
                }
            }

            history.replace({ search: searchPath });


            let taxLocation = params.tax_country; // eslint-disable-line
            if (taxLocation) {
                setParamsPlaces({
                    name: 'jobs/list/places',
                    method: 'post',
                    params: '?lang=' + lang + '&country=' + taxLocation
                });
            } else {
                const places = dataFetch.content.top.filters.list.filter(item => item.id === 'tax_place');
                setParamsPlaces({});
                if (places.length > 0) {
                    places[0].choices = initialPlaces;
                }
            }
        }

    }, [load, params, params.tax_country]); // eslint-disable-line

    useEffect(() => {
        if (newFilters !== '') {
            setCookie('filters', JSON.stringify(newFilters), 60);
            dispatch(updateFilters(newFilters));
        }
    }, [newFilters]);

    useEffect(() => {
        if (isLoadedPlaces) {
            if (dataFetch.content.top.filters && dataFetch.content.top.filters.list && dataFetch.content.top.filters.list.length > 0) {
                const places = dataFetch.content.top.filters.list.filter(item => item.id === 'tax_place');
                if (places.length > 0) {
                    if (dataFetchPlaces.data) {
                        places[0].choices = dataFetchPlaces.data;
                    } else {
                        places[0].choices = initialPlaces;
                    }
                }
            }
        }
    }, [isLoadedPlaces]); // eslint-disable-line

    const renderPage = () => {
        const {
            metas,
            content: {
                top: { title },
                main: { btn_see_more, no_results, total_single, total_multiple },
                aside: { job_alert, cv },
                bottom: { testimonies, elevator }
            }
        } = dataFetch;

        const customPrimaryColor = colors?.primary;
        const haveCustomPrimaryColor = !!customPrimaryColor;

        const customColorStyles = haveCustomPrimaryColor ? { color: customPrimaryColor } : {};

        return (
            <>
                <Metatags {...metas} />

                <CaaskAllOffers />

                <main className="main">

                    <MainHeader title={title} customPrimaryColor={customPrimaryColor} />

                    <div className="main__content" data-color="purple">
                        {result.data && result.data.filters &&
                            <SearchOffers offersPage={true} filters={result.data.filters} value={value}
                                handleChange={handleChange}
                                handleFilters={handleFilters} selected={selected} handleRemove={handleRemove}
                                customPrimaryColor={customPrimaryColor}
                                restricted={restricted} />
                        }
                        <div className="container">
                            <div className="main__contentFlex">
                                <div className="main__content-primary">
                                    {itemsLoaded ?
                                        <>
                                            {result?.data?.total && result.data.total > 0 ?
                                                <>
                                                    <h2 className="title-30 title-30--alt" style={customColorStyles}>
                                                        <strong>{result.data.total} </strong>
                                                        {result.data.total < 2 ? total_single : total_multiple}
                                                    </h2>

                                                    <div className="offers">
                                                        {items.map((item, key) => <Offer key={key} {...item}
                                                            restricted={restricted}
                                                            restrictedBrands={restrictedBrands}
                                                            offersPage={true} customPrimaryColor={customPrimaryColor} />)}
                                                    </div>
                                                </>
                                                :
                                                <h2 className="title-30 title-30--alt"
                                                    style={customColorStyles}>{no_results}</h2>
                                            }

                                            {resultIsLoaded === false && <LoaderResults />}

                                            {result?.data?.total && resultIsLoaded && result.data.total > (params.from + params.size) &&
                                                <div className="c-search-button">
                                                    <button type="button" className="c-button js-loadmore"
                                                        onClick={handleLoadMore}>{btn_see_more}</button>
                                                </div>
                                            }
                                        </>
                                        :
                                        <LoaderResults />
                                    }
                                </div>
                                <div className="main__content-aside">
                                    {((window.location.href.indexOf(process.env.REACT_APP_DOMAIN_1) > -1 || window.location.hostname === "localhost") && lang === 'fr') &&
                                        <div className="c-caask-mobile" />
                                    }
                                    {
                                        (job_alert && Object.keys(job_alert).length > 0) &&
                                        <AlertPush {...job_alert} />
                                    }
                                    {itemsLoaded2 &&
                                        <>                               
                                            {result?.data?.total && result.data.total > 0 &&
                                                <CMap items={items2} />
                                            }
                                        </>
                                    }
                                    {!restricted &&
                                        <>
                                            {(cv && Object.keys(cv).length > 0) &&
                                                <ScoringPush {...cv} />
                                            }
                                        </>
                                    }
                                    {
                                        dynamicsBlocs && dynamicsBlocs.length > 0 && (
                                            dynamicsBlocs.map((bloc, index) => (
                                                <AlertPush withIcon={false} key={index} {...bloc} />
                                            ))
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {!restricted &&
                        <>
                            {(testimonies && testimonies.items && testimonies.items.length > 0) &&
                                <>
                                    {(elevator && Object.keys(elevator).length > 0) ?
                                        (
                                            <TestimonyList {...testimonies} elevator={elevator} />
                                        ) : (
                                            <TestimonyList {...testimonies} />
                                        )
                                    }
                                </>
                            }
                        </>
                    }


                </main>
            </>
        )
    };

    return isLoaded ? renderPage() : <LoaderPage />
};


export default TemplateOfferList;