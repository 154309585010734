import { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

/**
 * GenricType is used because i don't know the type of returned data
 * @template T
 * @param {T} data
 * @return {T}
 */
/**
 * This the state returned by custom useFetch hook
 * @template T
 * @typedef {Object} State
 * @property {T[]} data
 * @property {boolean} isLoading
 * @property {boolean} dataIsLoaded
 * @property {Error} error
 */
/**
 * The useFetch hook accept three parameters; the request info, options from axios, and the method you want to use
 * @function
 * @template T
 * @param {RequestInfo} request 
 * @param {AxiosRequestConfig} options 
 * @param {(AxiosInstance['get'] | AxiosInstance['post'] | AxiosInstance['patch'] | AxiosInstance['put'] | AxiosInstance['delete'])} method 
 * @returns {State<T>}
 */
export function useFetch(request, method, options) {
    const { params } = options;
    const { site } = useSelector(state => state.global);

    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataIsLoaded, setDataIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const source = axios.CancelToken.source();
        setDataIsLoaded(false);
        setIsLoading(true);
        (async () => {
            try {
                if(params) {
                    const response = await axios[method](`${site}/wp-json/${process.env.REACT_APP_API_NAME}/v1/${request}`, params, { cancelToken: source.token });
                    const data = await response.data;
                    setResponse(data);
                    setDataIsLoaded(true);
                    setIsLoading(false);
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    setError(error);
                    setDataIsLoaded(false);
                    setIsLoading(false);
                }
            }
        })()
        return () => {
            source.cancel('API call cancelled');
        }
    }, [request, params])

    return { response, isLoading, dataIsLoaded, error }
}

export function useFetch2(request, method, options) {
    const { params } = options;
    const { site } = useSelector(state => state.global);

    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataIsLoaded, setDataIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const source = axios.CancelToken.source();
        setDataIsLoaded(false);
        setIsLoading(true);
        (async () => {
            try {
                if(params) {
                    const response = await axios[method](`${request}`, params, { cancelToken: source.token });
                    const data = await response.data;
                    setResponse(data);
                    setDataIsLoaded(true);
                    setIsLoading(false);
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    setError(error);
                    setDataIsLoaded(false);
                    setIsLoading(false);
                }
            }
        })()
        return () => {
            source.cancel('API call cancelled');
        }
    }, [request, params])

    return { response, isLoading, dataIsLoaded, error }
}
