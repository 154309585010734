import React, { useEffect } from "react";

// Libraries
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster'
import { MapContainer, TileLayer, Marker, Popup } from "../../node_modules/react-leaflet";
import CLink from "./link";
import { useSelector } from "react-redux";

const CMap = ({ items }) => {

    console.log("ITEMS", items)

    // Map center
    const { global: { map: mapCenter } } = useSelector(state => state.global);

    useEffect(() => {
        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("../Assets/img/icon-marker@2x.png"),
            iconUrl: require("../Assets/img/icon-marker.png"),
            shadowUrl: require("../Assets/img/icon-marker-shadow.png"),
            iconSize: [22, 31], // size of the icon
            shadowSize: [17, 24], // size of the shadow
            iconAnchor: [11, 31], // point of the icon which will correspond to marker's location
        });
    }, []);

    const getLatLngBounds = () => {
        let markers = [];
        if (items && items.length > 0) {
            items.map(({ coordinates }) => { // eslint-disable-line
                if (coordinates) {
                    if (Object.keys(coordinates).length > 0) {
                        const position = [];
                        position.push(coordinates.latitude);
                        position.push(coordinates.longitude);
                        markers.push(position);
                    }
                }
            });
            if (markers.length === 0) {
                // France
                // markers.push([46.23, 2.21]);
                // markers = false;
            }
        } else {
            // France
            // markers.push([46.23, 2.21]);
            // markers = false;
        }
        //  console.log(markers);
        return markers;
    };

    return (
        <div className="c-offers-map">
            <MapContainer className="c-map"
                bounds={getLatLngBounds()} boundsOptions={{ padding: [30, 30] }}
                center={mapCenter && mapCenter.latitude && mapCenter.longitude ? [mapCenter.latitude, mapCenter.longitude] : [46.23, 2.21]}
                zoom={5}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
                />

                {(items && items.length > 0) &&
                    <MarkerClusterGroup>
                        {
                            items.map(({ title, brand, localisations, contract, link }, key) => {

                                if(!localisations){
                                    return false;
                                }

                                return localisations.map(({ coordinates, localisation }, key2) => {
                                    if (coordinates && Object.keys(coordinates).length > 0 && coordinates.latitude !== "" && coordinates.longitude !== "") {
                                        const position = [];
                                        position.push(coordinates.latitude);
                                        position.push(coordinates.longitude);

                                        return (
                                            <Marker position={position} key={key2}>
                                                <Popup className="c-map-popup">
                                                    <h3 className="title-25 c-offer-title">{title}</h3>
                                                    {brand &&
                                                        <div className="c-offer-brand">{brand}</div>
                                                    }
                                                    <ul className="c-offer-infos">
                                                        {localisation &&
                                                            <li><i className="icon-location"></i>{localisation}</li>
                                                        }
                                                        {contract &&
                                                            <li><i className="icon-contract"></i>{contract}</li>
                                                        }
                                                    </ul>
                                                    <CLink className="c-button" {...link} />
                                                </Popup>
                                            </Marker>
                                        )
                                    }
                                })
                            })
                        }

                    </MarkerClusterGroup>
                }

            </MapContainer>
        </div>
    )
};
export default CMap;